import { MDBCol, MDBContainer, MDBRow } from "mdbreact";

import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';

const App = ({location}) => {
    return (
      <Layout>
        <SEO 
          title={`404 | Genesis | ${location.href}`}
          description={`Hey! You just hit a page that doesn't exist...`}
          keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
        />
        <main>
          <MDBContainer tag="section" id="maincontent">
            <MDBRow center>
              <MDBCol xs="12" md="12" lg="12" className="col-12 contentCenter404">
                <p>Hey! You just hit a page that doesn't exist...</p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </Layout>
    );
}

export default App;